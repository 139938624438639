import OvenPlayer from 'ovenplayer';
import { useState } from 'react';

export const useLastPlayerIndex = () => {
  const [lastIndex, setLastIndex] = useState(0);

  const updateLastIndex = () => {
    const activePlayers = OvenPlayer.getPlayerList();
    setLastIndex(activePlayers.length > 0 ? activePlayers.length - 1 : 0);
  };

  return { lastIndex, updateLastIndex };
};
