import { ActiveStreamProps, StreamVariantUrlEnum } from '@shared/ui/StreamHeader/types';
import {
  ArrowRight,
  AttentionPlayer,
  Camera,
  Menu15,
  Menu20,
  Predator16,
  Target15,
  Target16,
  Target20,
  Trash,
  Vision16,
} from '@shared/assets/icons/components';
import { Button, ContextMenu, IconButton, Spinner } from '@shared/ui';
import { ContextMenuItemsProps, ContextMenuVariantEnum } from '@shared/ui/ContextMenu/types';
import { FC, useEffect, useRef, useState } from 'react';
import { useClientSize, useElementSize, useHover, useOnlineStatus, useOutsideClick, useToggle } from '@shared/hooks';
import { useNavigate, useParams } from 'react-router-dom';

import { CameraResponseDto } from '@shared/services/apiService/apiService_videowall';
import ReactHlsPlayer from 'react-hls-player';
import { VideoWallPath } from '@shared/constants/routes';
import { WebRtcPlayer } from '@entities/WebRtcPlayer/WebRtcPlayer';
import classNames from 'classnames';
import styles from './stream.module.scss';
import stylesStreamHeader from '@shared/ui/StreamHeader/streamHeader.module.scss';
import { useWindowSize } from '@shared/hooks/useWindowSize';

type StreamProps = {
  index?: number;
  className?: string;
  stream: CameraResponseDto;
  hasStockVideoWall: boolean;
  hasFooter?: boolean;
  onSelectSwapStream?: (id: string) => void;
  onDeleteStream?: (id: string) => void;
  updateLastIndex?: () => void;
};

export const Stream: FC<StreamProps> = ({
  index,
  className,
  stream,
  hasStockVideoWall,
  hasFooter = true,
  onSelectSwapStream,
  onDeleteStream,
  updateLastIndex,
}) => {
  const { videoWallId } = useParams();
  const [isError, setIsError] = useState(false);
  const handleError = (e: any) => {
    setIsError(true);
  };
  const { id, name, defaultStreamUrl, maskedStreamUrl, predatorStreamUrl, status } = stream;

  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  const isOnline = useOnlineStatus();

  const streamRef = useRef(null);

  const [streamRefSize] = useElementSize(streamRef, (width / height) as unknown as string);

  const streamHeight = streamRefSize.width / streamRefSize.height >= 16 / 9 ? streamRefSize.height : '44vh';

  const isStreamHover = useHover(streamRef);

  const { state: isContextMenuVisible, toggle: toggleContextMenu } = useToggle();
  const { state: isVisionMenuVisible, toggle: toggleVisionMenu } = useToggle();

  const [isLoadingError, setIsLoadingError] = useState(false);

  const [activeStream, setStream] = useState<ActiveStreamProps>({
    streamVariant: StreamVariantUrlEnum.DEFAULT,
    streamUrl: defaultStreamUrl,
  });

  const currentStreamStorage = localStorage.getItem('streamVariants');
  let streamVariants = currentStreamStorage ? JSON.parse(currentStreamStorage) : [];
  const existingStreamIndex = streamVariants.findIndex((item: any) => item.id === id);

  const handleSelectStream = () => {
    onSelectSwapStream && onSelectSwapStream(id);
  };

  const handleViewStream = () => {
    navigate(VideoWallPath.STREAM_BY_ID(videoWallId, id));
    window.location.reload();
  };

  const handleDelete = async () => {
    if (!!onDeleteStream) {
      await onDeleteStream(id);
      toggleContextMenu();
    }

    streamVariants = streamVariants.filter((item: any) => item.id !== id);
    localStorage.setItem('streamVariants', JSON.stringify(streamVariants));

    window.location.reload();
  };

  const contextMenu: ContextMenuItemsProps[] = [
    {
      icon: <Camera />,
      title: 'Выбрать камеру',
      onClick: handleSelectStream,
      variant: ContextMenuVariantEnum.ACTION,
    },
    {
      icon: <Trash />,
      title: 'Удалить камеру',
      onClick: handleDelete,
      variant: ContextMenuVariantEnum.DEFAULT,
    },
  ];

  const contextMenuStockVideoWall: ContextMenuItemsProps[] = [
    {
      icon: <Camera />,
      title: 'Выбрать камеру',
      onClick: handleSelectStream,
      variant: ContextMenuVariantEnum.ACTION,
    },
  ];

  const isStatusError = status === 'off' || status === 'error';
  const statusText = isStatusError ? 'Ошибка' : 'Запись';

  const classRoot = classNames(styles.root, className);

  const classesIndicator = classNames(styles.streamIndicator, {
    [styles.streamIndicatorInactive]: isStatusError,
  });

  const contextMenuButtonRef = useRef(null);
  const visionMenuButtonRef = useRef(null);

  const contextMenuRef = useRef(null);
  const visionMenuRef = useRef(null);

  const hasControlsVisible = isStreamHover || isContextMenuVisible || isVisionMenuVisible;

  const isWebRtcStream = activeStream.streamUrl.startsWith('wss://');

  useOutsideClick(contextMenuRef, contextMenuButtonRef, toggleContextMenu);
  useOutsideClick(visionMenuRef, visionMenuButtonRef, toggleVisionMenu);

  const handleSelectStreamVariant = ({ streamVariant, streamUrl }: ActiveStreamProps) => {
    setStream({ streamVariant, streamUrl });

    if (existingStreamIndex !== -1) {
      streamVariants[existingStreamIndex].activeStream = streamUrl;
      streamVariants[existingStreamIndex].variant = streamVariant;
    } else {
      streamVariants.push({
        id: id,
        activeStream: streamUrl,
        variant: streamVariant,
      });
    }

    localStorage.setItem('streamVariants', JSON.stringify(streamVariants));

    if (streamVariant !== activeStream.streamVariant) {
      toggleVisionMenu();
    }

    // window.location.reload();

    updateLastIndex?.();
  };

  const visionMenuItems: ContextMenuItemsProps[] = [
    {
      icon: <Vision16 />,
      title: 'Обычный режим',
      onClick: () =>
        handleSelectStreamVariant({ streamVariant: StreamVariantUrlEnum.DEFAULT, streamUrl: defaultStreamUrl }),
      variant: ContextMenuVariantEnum.SELECT,
      value: activeStream.streamVariant === StreamVariantUrlEnum.DEFAULT,
    },
    {
      icon: <Target16 />,
      title: 'Режим свой/чужой',
      onClick: () =>
        handleSelectStreamVariant({ streamVariant: StreamVariantUrlEnum.MASKED, streamUrl: maskedStreamUrl }),
      variant: ContextMenuVariantEnum.SELECT,
      value: streamVariants[existingStreamIndex]?.variant === StreamVariantUrlEnum.MASKED,
    },
    {
      icon: <Predator16 />,
      title: 'Режим хищника',
      onClick: () =>
        handleSelectStreamVariant({ streamVariant: StreamVariantUrlEnum.PREDATOR, streamUrl: predatorStreamUrl }),
      variant: ContextMenuVariantEnum.SELECT,
      value: streamVariants[existingStreamIndex]?.variant === StreamVariantUrlEnum.PREDATOR,
    },
  ];

  const { getIsBreakpoint } = useClientSize();
  const isDesktopFullHD = getIsBreakpoint('desktopFullHD');

  const videoPlayerRef = useRef(null);

  useEffect(() => {
    if (isOnline) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [isOnline]);

  useEffect(() => {
    if (existingStreamIndex !== -1 && streamVariants[existingStreamIndex].activeStream) {
      setStream({
        streamVariant: streamVariants[existingStreamIndex].variant,
        streamUrl: streamVariants[existingStreamIndex].activeStream,
      });
    }
    updateLastIndex?.();
  }, [id, currentStreamStorage]);

  return (
    <>
      <div ref={streamRef} className={classRoot}>
        {isError && (
          <div className={classRoot}>
            <div className={styles.select}>
              <div className={styles.content}>
                <AttentionPlayer style={{ zoom: 0.6 }} />
                <p className={styles.text}>
                  Ошибка загрузки камеры. Возможно, она не в сети или интернет-соединение нестабильно
                </p>
                <Button variant="primary" size="small" onClick={handleSelectStream} className={styles.button}>
                  Выбрать камеру
                </Button>
              </div>
            </div>
          </div>
        )}
        {isWebRtcStream ? (
          <WebRtcPlayer activeStream={activeStream} playerRef={videoPlayerRef} index={index} />
        ) : (
          <ReactHlsPlayer
            playerRef={videoPlayerRef}
            src={activeStream.streamUrl}
            autoPlay={true}
            muted={true}
            style={{ aspectRatio: 16 / 9 }}
            width="100%"
            onError={e => handleError(e)}
          />
        )}
        {hasControlsVisible && (
          <>
            <div className={stylesStreamHeader.root}>
              <div className={stylesStreamHeader.controls}>
                <div className={stylesStreamHeader.leftIcons}>
                  <IconButton ref={contextMenuButtonRef} onClick={toggleContextMenu} size={isDesktopFullHD ? 32 : 24}>
                    {isDesktopFullHD ? <Menu20 /> : <Menu15 />}
                  </IconButton>
                  <IconButton ref={visionMenuButtonRef} onClick={toggleVisionMenu} size={isDesktopFullHD ? 32 : 24}>
                    {streamVariants[existingStreamIndex]?.variant === StreamVariantUrlEnum.DEFAULT ? (
                      <Vision16 />
                    ) : streamVariants[existingStreamIndex]?.variant === StreamVariantUrlEnum.MASKED ? (
                      <Target16 />
                    ) : streamVariants[existingStreamIndex]?.variant === StreamVariantUrlEnum.PREDATOR ? (
                      <Predator16 />
                    ) : (
                      <Vision16 />
                    )}
                  </IconButton>
                </div>
                <IconButton onClick={handleViewStream} size={isDesktopFullHD ? 32 : 24}>
                  <ArrowRight className={stylesStreamHeader.arrowRight} />
                </IconButton>
              </div>
            </div>
            {hasFooter && (
              <div className={styles.footer}>
                <p className={styles.streamName}>{name}</p>
                <div className={styles.status}>
                  <div className={classNames(classesIndicator)} />
                  <p className={styles.statusText}>{statusText}</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <ContextMenu
        ref={contextMenuRef}
        anchorElement={contextMenuButtonRef.current}
        menu={hasStockVideoWall ? contextMenuStockVideoWall : contextMenu}
        isVisible={isContextMenuVisible}
      />
      <ContextMenu
        ref={visionMenuRef}
        anchorElement={visionMenuButtonRef.current}
        menu={visionMenuItems}
        isVisible={isVisionMenuVisible}
      />
    </>
  );
};
