import { baseApiIntegration as api } from '../../store/api/baseApi_integration';
export const addTagTypes = [
  'Prometheus',
  'Manage integrations with ACS services i.e., RusGuard, Trassir etc..',
  'Directly read and write to external ACS providers i.e., RusGuard, Trassir etc..',
  'Readers controller',
  'Security User controller',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      appControllerGetHello: build.query<AppControllerGetHelloApiResponse, AppControllerGetHelloApiArg>({
        query: () => ({ url: `/` }),
      }),
      prometheusControllerWebhook: build.query<
        PrometheusControllerWebhookApiResponse,
        PrometheusControllerWebhookApiArg
      >({
        query: () => ({ url: `/metrics` }),
        providesTags: ['Prometheus'],
      }),
      integrationMetaControllerCreateIntegrationMeta: build.mutation<
        IntegrationMetaControllerCreateIntegrationMetaApiResponse,
        IntegrationMetaControllerCreateIntegrationMetaApiArg
      >({
        query: queryArg => ({
          url: `/v1/integration-meta`,
          method: 'POST',
          body: queryArg.createIntegrationMetaRequestDto,
        }),
        invalidatesTags: ['Manage integrations with ACS services i.e., RusGuard, Trassir etc..'],
      }),
      integrationMetaControllerDeleteIntegrationMeta: build.mutation<
        IntegrationMetaControllerDeleteIntegrationMetaApiResponse,
        IntegrationMetaControllerDeleteIntegrationMetaApiArg
      >({
        query: queryArg => ({ url: `/v1/integration-meta/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Manage integrations with ACS services i.e., RusGuard, Trassir etc..'],
      }),
      integrationMetaControllerCreateIntegrationMetaMultiple: build.mutation<
        IntegrationMetaControllerCreateIntegrationMetaMultipleApiResponse,
        IntegrationMetaControllerCreateIntegrationMetaMultipleApiArg
      >({
        query: queryArg => ({
          url: `/v1/integration-meta/multiple-async`,
          method: 'POST',
          body: queryArg.createIntegrationMetaMultipleRequestDto,
        }),
        invalidatesTags: ['Manage integrations with ACS services i.e., RusGuard, Trassir etc..'],
      }),
      integrationMetaControllerDeleteIntegrationMetaMultiple: build.mutation<
        IntegrationMetaControllerDeleteIntegrationMetaMultipleApiResponse,
        IntegrationMetaControllerDeleteIntegrationMetaMultipleApiArg
      >({
        query: queryArg => ({
          url: `/v1/integration-meta/multiple-async`,
          method: 'DELETE',
          params: { integrationMetaIds: queryArg.integrationMetaIds },
        }),
        invalidatesTags: ['Manage integrations with ACS services i.e., RusGuard, Trassir etc..'],
      }),
      integrationMetaControllerGetExternalData: build.query<
        IntegrationMetaControllerGetExternalDataApiResponse,
        IntegrationMetaControllerGetExternalDataApiArg
      >({
        query: queryArg => ({ url: `/v1/integration-meta/by-access-key/${queryArg.accessKey}` }),
        providesTags: ['Manage integrations with ACS services i.e., RusGuard, Trassir etc..'],
      }),
      integrationMetaControllerForceChangeDynamicAccessKey: build.mutation<
        IntegrationMetaControllerForceChangeDynamicAccessKeyApiResponse,
        IntegrationMetaControllerForceChangeDynamicAccessKeyApiArg
      >({
        query: queryArg => ({
          url: `/v1/integration-meta/force-change-dynamic-access-key`,
          method: 'PUT',
          body: queryArg.forceChangeDynamicAccessKeyRequestDto,
        }),
        invalidatesTags: ['Manage integrations with ACS services i.e., RusGuard, Trassir etc..'],
      }),
      integrationMetaControllerGetAccessKeys: build.query<
        IntegrationMetaControllerGetAccessKeysApiResponse,
        IntegrationMetaControllerGetAccessKeysApiArg
      >({
        query: queryArg => ({
          url: `/v1/integration-meta/access-keys/${queryArg.visitorId}`,
          params: { strategy: queryArg.strategy },
        }),
        providesTags: ['Manage integrations with ACS services i.e., RusGuard, Trassir etc..'],
      }),
      integrationDirectlyControllerGetVisitorsWithAccessKeys: build.query<
        IntegrationDirectlyControllerGetVisitorsWithAccessKeysApiResponse,
        IntegrationDirectlyControllerGetVisitorsWithAccessKeysApiArg
      >({
        query: queryArg => ({
          url: `/v1/integration-directly/visitors`,
          params: { companyId: queryArg.companyId, strategy: queryArg.strategy },
        }),
        providesTags: ['Directly read and write to external ACS providers i.e., RusGuard, Trassir etc..'],
      }),
      integrationDirectlyControllerCreateAccessKey: build.mutation<
        IntegrationDirectlyControllerCreateAccessKeyApiResponse,
        IntegrationDirectlyControllerCreateAccessKeyApiArg
      >({
        query: queryArg => ({
          url: `/v1/integration-directly/visitors/${queryArg.externalId}/access-key`,
          method: 'POST',
          body: queryArg.directBaseRequestDto,
        }),
        invalidatesTags: ['Directly read and write to external ACS providers i.e., RusGuard, Trassir etc..'],
      }),
      integrationDirectlyControllerDeleteAccessKey: build.mutation<
        IntegrationDirectlyControllerDeleteAccessKeyApiResponse,
        IntegrationDirectlyControllerDeleteAccessKeyApiArg
      >({
        query: queryArg => ({
          url: `/v1/integration-directly/visitors/${queryArg.externalId}/access-key`,
          method: 'DELETE',
          params: { companyId: queryArg.companyId, strategy: queryArg.strategy },
        }),
        invalidatesTags: ['Directly read and write to external ACS providers i.e., RusGuard, Trassir etc..'],
      }),
      readerControllerGetVisitorsWithAccessKeys: build.query<
        ReaderControllerGetVisitorsWithAccessKeysApiResponse,
        ReaderControllerGetVisitorsWithAccessKeysApiArg
      >({
        query: queryArg => ({ url: `/v1/readers/by-device-key/${queryArg.deviceKey}` }),
        providesTags: ['Readers controller'],
      }),
      securityUserAccessPointControllerGetAccessPointByIds: build.query<
        SecurityUserAccessPointControllerGetAccessPointByIdsApiResponse,
        SecurityUserAccessPointControllerGetAccessPointByIdsApiArg
      >({
        query: queryArg => ({ url: `/v1/security-user/access-points/by-ids`, params: { ids: queryArg.ids } }),
        providesTags: ['Security User controller'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as apiService_integration };
export type AppControllerGetHelloApiResponse = /** status 200  */ string;
export type AppControllerGetHelloApiArg = void;
export type PrometheusControllerWebhookApiResponse = unknown;
export type PrometheusControllerWebhookApiArg = void;
export type IntegrationMetaControllerCreateIntegrationMetaApiResponse =
  /** status 200 Successfully created integration meta */
    | CreateIntegrationMetaResponseDto
    | /** status 201  */ CreateIntegrationMetaResponseDto;
export type IntegrationMetaControllerCreateIntegrationMetaApiArg = {
  createIntegrationMetaRequestDto: CreateIntegrationMetaRequestDto;
};
export type IntegrationMetaControllerDeleteIntegrationMetaApiResponse =
  /** status 200 Successfully deleted integration meta */ number;
export type IntegrationMetaControllerDeleteIntegrationMetaApiArg = {
  id: string;
};
export type IntegrationMetaControllerCreateIntegrationMetaMultipleApiResponse =
  /** status 200 Multiple creation in progress */ string | /** status 201  */ string;
export type IntegrationMetaControllerCreateIntegrationMetaMultipleApiArg = {
  createIntegrationMetaMultipleRequestDto: CreateIntegrationMetaMultipleRequestDto;
};
export type IntegrationMetaControllerDeleteIntegrationMetaMultipleApiResponse =
  /** status 200 Multiple deletion in progress */ string;
export type IntegrationMetaControllerDeleteIntegrationMetaMultipleApiArg = {
  integrationMetaIds: string[];
};
export type IntegrationMetaControllerGetExternalDataApiResponse =
  /** status 200 Get integration meta */ IntegrationMetaDto[];
export type IntegrationMetaControllerGetExternalDataApiArg = {
  accessKey: string;
};
export type IntegrationMetaControllerForceChangeDynamicAccessKeyApiResponse =
  /** status 200 Successfully retrieved integration meta */ IntegrationMetaDto;
export type IntegrationMetaControllerForceChangeDynamicAccessKeyApiArg = {
  forceChangeDynamicAccessKeyRequestDto: ForceChangeDynamicAccessKeyRequestDto;
};
export type IntegrationMetaControllerGetAccessKeysApiResponse =
  /** status 200 Successfully retrieved access keys */ GetAccessKeysResponseDto[];
export type IntegrationMetaControllerGetAccessKeysApiArg = {
  visitorId: string;
  strategy?: 'rus_guard' | 'trassir' | 'none';
};
export type IntegrationDirectlyControllerGetVisitorsWithAccessKeysApiResponse =
  /** status 200 Successfully retrieved visitors */ IntegrationVisitorDto[];
export type IntegrationDirectlyControllerGetVisitorsWithAccessKeysApiArg = {
  companyId: string;
  strategy: 'rus_guard' | 'trassir' | 'none';
};
export type IntegrationDirectlyControllerCreateAccessKeyApiResponse =
  /** status 201 Successfully created access key for visitor */ string;
export type IntegrationDirectlyControllerCreateAccessKeyApiArg = {
  externalId: string;
  directBaseRequestDto: DirectBaseRequestDto;
};
export type IntegrationDirectlyControllerDeleteAccessKeyApiResponse =
  /** status 200 Successfully deleted access key form visitor */ number;
export type IntegrationDirectlyControllerDeleteAccessKeyApiArg = {
  externalId: string;
  companyId: string;
  strategy: 'rus_guard' | 'trassir' | 'none';
};
export type ReaderControllerGetVisitorsWithAccessKeysApiResponse =
  /** status 200 Successfully retrieved reader */ GetReaderWithAccessPointResponseDto;
export type ReaderControllerGetVisitorsWithAccessKeysApiArg = {
  deviceKey: string;
};
export type SecurityUserAccessPointControllerGetAccessPointByIdsApiResponse =
  /** status 200 Successfully retrieved access points */ AccessPointRequestDto[];
export type SecurityUserAccessPointControllerGetAccessPointByIdsApiArg = {
  ids: string[];
};
export type CreateIntegrationMetaResponseDto = {
  companyId: string;
  strategy: 'rus_guard' | 'trassir' | 'none';
  visitorId: string;
  officeId: string;
  accessKey?: string | null;
  dynamicAccessKey?: string | null;
  dynamicAccesskeyEnabled: boolean;
  externalCorelationId: string;
};
export type CreateIntegrationMetaRequestDto = {
  strategy: 'rus_guard' | 'trassir' | 'none';
  visitorId: string;
  companyId: string;
  officeId: string;
  externalCorelationId: string;
  dynamicAccessKeyEnabled: boolean;
};
export type CreateIntegrationMetaMultipleRequestDto = {
  data: CreateIntegrationMetaRequestDto[];
};
export type IntegrationMetaDto = {
  companyId: string;
  strategy: 'rus_guard' | 'trassir' | 'none';
  visitorId: string;
  officeId: string;
  accessKey?: string | null;
  dynamicAccessKey?: string | null;
  dynamicAccesskeyEnabled: boolean;
  externalCorelationId: string;
};
export type ForceChangeDynamicAccessKeyRequestDto = {
  strategy: 'rus_guard' | 'trassir' | 'none';
  visitorId: string;
  officeId: string;
};
export type GetAccessKeysResponseDto = {
  strategy: 'rus_guard' | 'trassir' | 'none';
  officeId: string;
  accessKey?: string | null;
};
export type IntegrationVisitorDto = {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  secondName?: string | null;
  accessKey: string | null;
  phone: string | null;
  createdAt: string;
  updatedAt: string;
};
export type DirectBaseRequestDto = {
  companyId: string;
  strategy: 'rus_guard' | 'trassir' | 'none';
};
export type GetReaderWithAccessPointResponseDto = {
  id: string;
  deviceKey: string;
  password: string;
  accessPointId: string;
  createdAt: string;
  updatedAt: string;
  areaId: string;
  officeId: string;
  accessPointExternalId: string;
  accessPointPassageType: 'entrance' | 'exit';
  accessPointHardwareType: 'entrance' | 'exit';
};
export type IntegrationStrategyKind = 'rus_guard' | 'trassir' | 'none';
export type AccessPointTypeKind = 'entrance' | 'exit';
export type AccessPointHwTypeKind =
  | 'unspecified'
  | 'gate'
  | 'tourniquet'
  | 'room key'
  | 'face controller'
  | 'one sided door'
  | 'door'
  | 'key cabinet';
export type AccessPointRequestDto = {
  id: string;
  name: string;
  officeId: string;
  companyId: string;
  strategy: IntegrationStrategyKind;
  type: AccessPointTypeKind;
  hwType: AccessPointHwTypeKind;
  createdAt: string;
};
export const {
  useAppControllerGetHelloQuery,
  useLazyAppControllerGetHelloQuery,
  usePrometheusControllerWebhookQuery,
  useLazyPrometheusControllerWebhookQuery,
  useIntegrationMetaControllerCreateIntegrationMetaMutation,
  useIntegrationMetaControllerDeleteIntegrationMetaMutation,
  useIntegrationMetaControllerCreateIntegrationMetaMultipleMutation,
  useIntegrationMetaControllerDeleteIntegrationMetaMultipleMutation,
  useIntegrationMetaControllerGetExternalDataQuery,
  useLazyIntegrationMetaControllerGetExternalDataQuery,
  useIntegrationMetaControllerForceChangeDynamicAccessKeyMutation,
  useIntegrationMetaControllerGetAccessKeysQuery,
  useLazyIntegrationMetaControllerGetAccessKeysQuery,
  useIntegrationDirectlyControllerGetVisitorsWithAccessKeysQuery,
  useLazyIntegrationDirectlyControllerGetVisitorsWithAccessKeysQuery,
  useIntegrationDirectlyControllerCreateAccessKeyMutation,
  useIntegrationDirectlyControllerDeleteAccessKeyMutation,
  useReaderControllerGetVisitorsWithAccessKeysQuery,
  useLazyReaderControllerGetVisitorsWithAccessKeysQuery,
  useSecurityUserAccessPointControllerGetAccessPointByIdsQuery,
  useLazySecurityUserAccessPointControllerGetAccessPointByIdsQuery,
} = injectedRtkApi;
