import { AreaVisitorsMetricsProps, VisitorRoleEnum } from '@pages/Visitors/types';

import { REACT_APP_API_GEOMERGE_URL } from '@shared/constants/environments';

export const visitorRoleMapping = {
  [VisitorRoleEnum.EMPLOYEE]: 'Сотрудник',
  [VisitorRoleEnum.GUEST]: 'Гость',
  [VisitorRoleEnum.CONTRACTOR]: 'Подрядчик',
  [VisitorRoleEnum.UNIDENTIFIED]: 'Не распознан',
  [VisitorRoleEnum.SECURITY]: 'Охрана',
};

export const visitorGroupNameMapping = {
  [VisitorRoleEnum.EMPLOYEE]: 'Сотрудники',
  [VisitorRoleEnum.GUEST]: 'Гости',
  [VisitorRoleEnum.CONTRACTOR]: 'Подрядчики',
  [VisitorRoleEnum.UNIDENTIFIED]: 'Чужие',
  [VisitorRoleEnum.SECURITY]: 'Охрана',
};

export const VISITORS_PAGE_HEADING = 'Посетители в офисе';

export const VISITORS_MAP_API_BASE_URL = `${REACT_APP_API_GEOMERGE_URL}/v1/security-users`;

export const VisitorsMapPath = {
  OFFICES_METRICS: (officeId = ':officeId'): string => `${VISITORS_MAP_API_BASE_URL}/offices/by-id/${officeId}/metrics`,
  AREA_METRICS: (areaId = ':areaId'): string => `${VISITORS_MAP_API_BASE_URL}/areas/by-id/${areaId}/metrics`,
  VISITORS_POSITION: (areaId = ':areaId'): string =>
    `${VISITORS_MAP_API_BASE_URL}/areas/by-id/${areaId}/visitors-position`,
};

export const DEFAULT_VISITORS_MAP_POOLING_INTERVAL = 1000;

export const AreaMetrics: AreaVisitorsMetricsProps[] = [
  {
    visitorRole: VisitorRoleEnum.EMPLOYEE,
    visitorCount: 0,
  },
  {
    visitorRole: VisitorRoleEnum.GUEST,
    visitorCount: 0,
  },
  {
    visitorRole: VisitorRoleEnum.CONTRACTOR,
    visitorCount: 0,
  },
  {
    visitorRole: VisitorRoleEnum.UNIDENTIFIED,
    visitorCount: 0,
  },
  {
    visitorRole: VisitorRoleEnum.SECURITY,
    visitorCount: 0,
  },
];
